<template>
  <BittsModal
    :visible="showRoleDetailModal"
    :show-buttons="false"
    name="role-detail"
    :loading="loading || !ready"
    :title="modalTitle"
    class="c-edit-role"
    @closed="modalClosed"
  >
    <template #content>
      <BillingCTA
        v-if="isNotSupernode"
        description="Upgrade to Supernode to be able to edit the permission associated with this role"
        button-text="Talk to Sales"
        size="small"
        :billing-interaction="{
          cta: TEAM_CTAS.CUSTOM_ROLES,
          event_site: EVENT_SITES.ROLE_DETAIL_EDIT_PERMISSIONS_CTA,
          talkToSalesReason: 'Custom roles',
        }"
        class="mb-20 mt-0"
      />
      <BittsInput
        v-model="role.name"
        name="role-name"
        placeholder="Role name"
        :status="v$.role.name.$errors.length ? 'danger' : 'default'"
        :danger-text="v$.role.name.$errors?.at(-1)?.$message || ''"
        :disabled="isViewOnly"
        form-label="Role name"
        :class="[isViewOnly && 'c-text-input--disabled']"
      />
      <BittsTextArea
        v-model="role.description"
        class="mt-16"
        placeholder="Role description"
        :disabled="isViewOnly"
        :status="v$.role.description.$errors.length ? 'danger' : 'default'"
        form-label="Description"
        :danger-text="v$.role.description.$errors?.at(-1)?.$message || ''"
        :class="[isViewOnly && 'c-text-area--disabled']"
        :row-count="3"
      />
      <div class="text-sm text-neutral-text-weak mt-4">
        Note: Use a description to give your team context on the role
      </div>

      <BittsFormLabel
        class="permissions-label"
        :label="{
          title: 'Permissions',
          helpText: isViewOnly
            ? `${initialRoleName} role can't be edited`
            : 'Define the permissions for this role',
        }"
      />
      <div class="c-role-detail__permissions">
        <div
          v-for="(resource, resourceIndex) in allResourcesWithRemovals"
          :key="`resource_${resourceIndex}`"
          class="permission-item"
        >
          <span>{{ resource.displayName }}</span>
          <BittsPopover
            popover-class="c-edit-role__popover cursor-pointer"
            placement="left"
            trigger="click"
            :disabled="isViewOnly"
          >
            <div
              class="c-edit-role__permission-select"
              :class="{
                'cursor-pointer': !isViewOnly,
                'opacity-70': isViewOnly,
              }"
            >
              <span class="ml-8 truncate">
                {{ permissionName(resource) }}
              </span>
              <FontAwesomeIcon
                :icon="['fak', 'chevron-down']"
                :style="{
                  height: '10px',
                  width: '10px',
                  color: 'currentColor',
                }"
                class="mr-8"
              />
            </div>
            <template #content>
              <div v-if="!isViewOnly" class="w-260">
                <div
                  v-for="(perm, pindex) in permissionGroups[resource.name]"
                  :key="`permission_${pindex}`"
                  class="c-edit-role__permission-detail hover:text-brand-blue"
                  @click.prevent="setRolePermission(resource.name, perm)"
                >
                  <span class="flex justify-between items-center">
                    <span class="font-bold mb-5">{{ perm.display_name }}</span>
                    <FontAwesomeIcon
                      v-if="permissionSet[resource.name]?.id === perm.id"
                      :icon="['fak', 'check']"
                      :style="{
                        height: '12px',
                        width: '12px',
                        color: 'currentColor',
                      }"
                      class="text-brand-blue"
                    />
                  </span>
                  <span>{{ perm.description }}</span>
                </div>
              </div>
            </template>
          </BittsPopover>
        </div>
      </div>
      <BittsDivider class="mb-0" />
      <div
        v-if="isEditMode"
        class="flex flex-col mt-0 flex-1 md:flex-row md:justify-between md:mt-24 gap-12 px-24"
      >
        <BittsButton
          data-testid="delete-role"
          text="Delete role"
          variant="outline"
          type="danger"
          @click="openDelete"
        />
        <BittsButton
          data-testid="save-role-changes"
          text="Save Changes"
          :disabled="isViewOnly"
          :loading="loading"
          @click="saveRole"
        />
      </div>
      <div v-else-if="isCreateMode" class="flex flex-col mt-24 mr-24">
        <BittsButton
          text="Create Role"
          class="self-end w-full md:w-auto"
          :disabled="isViewOnly"
          :loading="loading"
          @click="createRole"
        />
      </div>
    </template>
  </BittsModal>
</template>

<script setup lang="ts">
import {
  BittsButton,
  BittsDivider,
  BittsFormLabel,
  BittsInput,
  BittsModal,
  BittsPopover,
  BittsTextArea,
} from '@crossbeam/bitts';
import { EVENT_SITES, TEAM_CTAS } from '@crossbeam/itly';

import { useHead } from '@unhead/vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import axios, { AxiosResponse } from 'axios';
import { partial, sortBy, values } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import BillingCTA from '@/components/billing/BillingCTA.vue';

import {
  AUDIT_LOG,
  SSO,
  TEMP_PARTNER_VISIBILITY,
} from '@/constants/feature_flags';
import {
  useBillingStore,
  useFeatureFlagStore,
  usePermissionsStore,
  useRolesStore,
} from '@/stores';
import { Permission, Role, RoleWithoutId } from '@/types/roles';
import urls from '@/urls';
import { indexBy } from '@/utils';

type RequesterType = (data: {
  name: string;
  description: string;
  permission_ids: string[];
}) => Promise<AxiosResponse>;

const { roleId = null, viewOnly = false } = defineProps<{
  roleId?: string | null;
  viewOnly?: boolean;
}>();

const router = useRouter();
const rolesStore = useRolesStore();
const featureFlagStore = useFeatureFlagStore();
const billingStore = useBillingStore();
const permissionsStore = usePermissionsStore();

const { ready } = storeToRefs(rolesStore);
const { isEnterpriseTier } = storeToRefs(billingStore);
const {
  allResources,
  permissionDetails,
  permissionGroups,
  defaultPermissions,
} = storeToRefs(permissionsStore);

const role = ref<Role | RoleWithoutId>({
  name: '',
  description: '',
  permissions: [],
});
const permissionSet = ref<Record<string, Permission>>({});
const loading = ref(true);
const initialRoleName = ref<string>('');
const showRoleDetailModal = ref(true);
const isViewOnlyMode = computed(() => viewOnly);
const isEditMode = computed(() => !isViewOnlyMode.value && !!roleId);
const isCreateMode = computed(() => !isViewOnlyMode.value && !isEditMode.value);

useHead({
  title: computed(() =>
    isEditMode.value && role.value.name ? role.value.name : 'Crossbeam',
  ),
});

onMounted(async () => {
  await Promise.all([rolesStore.readySync, permissionsStore.readySync]);
  role.value =
    roleId && rolesStore.getRoleById(roleId)
      ? (rolesStore.getRoleById(roleId) as Role)
      : {
          name: '',
          description: '',
          permissions: defaultPermissions.value,
        };

  permissionSet.value = indexBy(
    isCreateMode.value ? defaultPermissions.value : role.value.permissions,
    'resource',
  );
  loading.value = false;
  initialRoleName.value = role.value.name;
});

onUnmounted(() => {
  useHead({ title: 'Crossbeam' });
});

const rules = {
  role: {
    name: {
      required: helpers.withMessage('Please name your role.', required),
      printable: helpers.withMessage(
        'Please only use letters, numbers, spaces, and dashes in the role name.',
        (value: string) => {
          const VALID_ROLE_NAME = /^[a-zA-Z0-9_\- ]+$/;
          return VALID_ROLE_NAME.test(value);
        },
      ),
      isUnique: helpers.withMessage(isUniqueMessage, (value: string, _) => {
        const existingRole = rolesStore.getRoleByName(value);
        return !existingRole || existingRole.id === (role.value as Role)?.id;
      }),
    },
    description: {
      required: helpers.withMessage('A description is required.', required),
    },
  },
};

const v$ = useVuelidate(rules, { role }, { $lazy: true });

const allResourcesWithRemovals = computed(() => {
  /* More insight into why we are filtering members and roles
https://trello.com/c/NfPkBB5n/
356-pentest-high-priority-our-permissions-and-
roles-system-allows-certain-users-to-escalate-their-own-privileges
Crux of the issue is that if you can manage roles you can change
your own role so that you can get data sharing or something like that */
  return sortBy(
    allResources.value.filter((resource) => {
      if (
        resource.name === 'members' ||
        resource.name === 'roles' ||
        (resource.name === 'audit-log' && !auditLogEnabled.value) ||
        (resource.name === 'sso' && !ssoFeatureEnabled.value) ||
        resource.name === 'threads' ||
        (resource.name === 'partner-visibility' &&
          !partnerVisibilityEnabled.value) ||
        resource.name === 'partner-creation'
      ) {
        return false;
      }

      return true;
    }),
    ['displayName'],
  );
});
const ssoFeatureEnabled = computed(() => {
  return featureFlagStore.hasFeatureFlag(SSO) || isEnterpriseTier.value;
});
const auditLogEnabled = computed(() => {
  return featureFlagStore.hasFeatureFlag(AUDIT_LOG) || isEnterpriseTier.value;
});
const partnerVisibilityEnabled = computed(() => {
  return featureFlagStore.hasFeatureFlag(TEMP_PARTNER_VISIBILITY);
});
const isNotSupernode = computed(() => {
  /* This view only mode is only based on billing tier:
  a user who has only the read:roles permission does not fall into this category.
  Theoretically it could be extended to include them, but there is more work to
  that than just editing the following line */
  return !isEnterpriseTier.value;
});
const isViewOnly = computed(() => {
  return isNotSupernode.value || isViewOnlyMode.value;
});
const modalTitle = computed(() => {
  if (isCreateMode.value) return 'Create New Role';
  return isViewOnly.value
    ? `View ${initialRoleName.value}`
    : `Edit ${initialRoleName.value}`;
});

async function modalClosed() {
  role.value.name = initialRoleName.value;
  showRoleDetailModal.value = false;
  await router.push({ name: 'roles' });
}
async function writeRole(requester: RequesterType) {
  v$.value.$touch();
  if (v$.value.$invalid) return;
  loading.value = true;
  try {
    await requester({
      name: role.value.name,
      description: role.value.description,
      permission_ids: values(permissionSet.value).map((p) => p.id),
    });
  } finally {
    await rolesStore.refreshRolesStore();
    loading.value = false;
    showRoleDetailModal.value = false;
    await router.push({ name: 'roles' });
  }
}
async function createRole() {
  await writeRole(partial(axios.post, urls.roles.create));
}
function setRolePermission(resource: string, permission: Permission) {
  permissionSet.value[resource] = permission;
}
async function saveRole() {
  await writeRole(
    partial(axios.put, urls.roles.update((role.value as Role).id)),
  );
}
function openDelete() {
  showRoleDetailModal.value = false;
  router.push({
    name: 'delete_role',
    params: { role_id: (role.value as Role).id },
  });
}
function permissionName(resource: { name: string }) {
  if (!resource.name) return '';
  const permissionId = permissionSet.value[resource.name]?.id;
  if (!permissionId) return '';
  return permissionDetails.value[permissionId]?.display_name;
}

/* Helpers function */
function isUniqueMessage({ $model }: { $model: string }) {
  return `There is already a role named ${$model}.`;
}
</script>
<style lang="pcss">
.c-role-detail__permissions {
  max-height: 315px;
  min-height: 170px;
  overflow-y: scroll;
  @apply mb-16;
}

.permission-item {
  @apply text-base mb-16 flex justify-between items-center;
}

.u-grey-box {
  border-radius: 3px;
  @apply border border-neutral-200;
  &:focus {
    @apply border-brand-teal;
  }
  &:focus-within {
    @apply border-brand-teal;
  }
  &:disabled {
    @apply bg-neutral-100 opacity-70;
  }
}
.c-text-input--disabled {
  & .disabled {
    @apply opacity-100;
  }
}

.c-text-area--disabled {
  & textarea.c-bitts-textarea.ant-input.disabled {
    @apply opacity-100;
  }
}

.c-edit-role__modal-body {
  @apply flex flex-col w-full;
}

.c-edit-role__permission-detail {
  @apply flex flex-col border border-neutral-200 p-20;
}
.c-edit-role__permission-select {
  @apply bg-neutral-200 w-120 h-25 flex justify-between items-center;
  border-radius: 2px;
}
.c-edit-role__sales-cta {
  @apply flex items-center font-bold text-info-text;
}
.c-edit-role__title {
  @apply font-bold mb-8 text-neutral-text-strong text-sm;
}

.c-edit-role {
  .c-bitts-modal__content {
    @apply flex flex-col;
  }
  .permissions-label.bitts-form-label {
    @apply mt-24 mb-12;
  }
}
</style>
