<template>
  <div class="c-segment-rule">
    <div class="c-segment-rule_item">
      <div class="font-bold text-m">{{ title }}</div>
      <BittsTag v-if="isRequired" color="info" size="small" variant="rounded">
        Required
      </BittsTag>
    </div>
    <div v-if="recordCount !== null" class="c-segment-rule_item">
      <BittsTag color="secondary" size="small" variant="rounded">
        {{ `${recordCount} ${pluralize(recordCount, 'record')}` }}
      </BittsTag>
      <BittsButton
        type="neutral"
        variant="outline"
        size="small"
        :center-icon="['fak', 'edit']"
        @click="handleEditSegmentRule"
      />
    </div>
    <BittsButton
      v-else
      :left-icon="['far', 'plus']"
      text="Create"
      size="small"
      @click="handleCreateSegmentRule"
    />
  </div>
</template>

<script setup lang="ts">
import { BittsButton, BittsTag } from '@crossbeam/bitts';

import { pluralize } from 'humanize-plus';
import { computed } from 'vue';

import { SegmentType, isRequiredMapping } from './constants';

const { segment, recordCount = null } = defineProps<{
  segment: SegmentType;
  recordCount?: number; // TODO: Remove this prop
}>();

const isRequired = computed(() => {
  return isRequiredMapping[segment];
});

const title = computed(() => {
  switch (segment) {
    case SegmentType.CUSTOMERS:
      return 'Customers';
    case SegmentType.PROSPECTS:
      return 'Prospects';
    case SegmentType.OPEN_OPPS:
      return 'Open Opportunities';
    case SegmentType.STRATEGICS:
      return 'Strategic Accounts';
    default:
      return '';
  }
});

function handleCreateSegmentRule() {
  console.log('Create segment rule');
}

function handleEditSegmentRule() {
  console.log('Edit segment rule');
}
</script>

<style scoped lang="pcss">
.c-segment-rule {
  @apply flex items-center justify-between p-16;
}
.c-segment-rule_item {
  @apply flex gap-10 items-center;
}
</style>
