<template>
  <BittsModalTwo
    :loading="!ready"
    title="Delete Data Source"
    description="Are you sure you want to delete your connection? Removing this data source will permanently delete all associated populations and sharing rules. This cannot be undone."
    primary-button-text="Yes, Continue"
    primary-button-type="danger"
    secondary-button-text="Discard"
    :width="400"
    save-text="Yes, Continue"
    cancel-text="No, Cancel"
    confirm-type="danger"
    :action-loading="deleting"
    :disabled="deleting || !ready || !canManageDataSources"
    @action="deleteFeed"
    @closed="closeModal"
  />
</template>

<script setup>
import { BittsModalTwo } from '@crossbeam/bitts';

import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import useAuth from '@/composables/useAuth';
import { captureException } from '@/errors';
import {
  allReady,
  useDataSharesStore,
  useFeedsStore,
  useFlashesStore,
  usePopulationsStore,
  useSourcesStore,
} from '@/stores';

const props = defineProps({
  feedId: {
    type: Number,
    required: true,
  },
  cancelDestination: {
    type: String,
    required: true,
  },
});

const router = useRouter();
const feedsStore = useFeedsStore();
const sourcesStore = useSourcesStore();

const { hasPermission } = useAuth();

const canManageDataSources = computed(() =>
  hasPermission('manage:data-sources'),
);

const deleting = ref(false);
const ready = allReady(sourcesStore, feedsStore);

async function deleteFeed() {
  const flashesStore = useFlashesStore();
  const populationsStore = usePopulationsStore();
  const dataSharesStore = useDataSharesStore();

  deleting.value = true;
  try {
    await feedsStore.removeFeed(props.feedId);
    await Promise.all([
      dataSharesStore.refreshDataSharesStore(),
      populationsStore.refreshPopulationsStore(),
      feedsStore.refreshFeedsStore(),
    ]);
    flashesStore.addSuccessFlash({ message: 'Data deletion initiated' });
    await router.push({ name: 'data-sources' });
  } catch (err) {
    captureException(err);
    flashesStore.addErrorFlash({
      message: 'There was an issue with your delete',
      description: 'If the error persists contact support@crossbeam.com',
    });
    closeModal();
  }
}

async function closeModal() {
  deleting.value = false;
  await router.push({ path: props.cancelDestination });
}
</script>
