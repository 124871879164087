export enum SegmentType {
  CUSTOMERS = 'customers',
  PROSPECTS = 'prospects',
  OPEN_OPPS = 'open-opportunities',
  STRATEGICS = 'strategic-accounts',
}

export const isRequiredMapping = {
  [SegmentType.CUSTOMERS]: true,
  [SegmentType.PROSPECTS]: true,
  [SegmentType.OPEN_OPPS]: true,
  [SegmentType.STRATEGICS]: false,
};
